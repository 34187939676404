<template>
  <confirm-dialog ref="confirm"></confirm-dialog>
  <subtitle>Emails</subtitle>

  <card label="NPS">
    <key-value-presenter
      label="Current NPS Workflow Step"
      :value="toWorkflowStep(model?.schemeBusiness?.currentNPSWorkflowStep)"
    />
    <key-value-presenter
      label="Next NPS Email Date"
      :value="$helpers.dateString(model?.schemeBusiness?.nextNPSEmailDate)"
    />
    <key-value-presenter
      label="Leave Date"
      :value="$helpers.dateString(model?.schemeBusiness?.leaveDate)"
    />
  </card>

  <div v-if="emails && emails.length > 0">
    <p>The following email sends have been requested to be processed by SendGrid.</p>
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">To</th>
          <th scope="col">CC</th>
          <th scope="col">Metadata</th>
          <th scope="col">Description</th>
          <th scope="col">Dates</th>
          <th scope="col" style="width: 1px"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="email in emails" :key="email.emailId">
          <td>
            <span v-for="to in email.to" :key="to.address">
              <b>{{ to.name }}</b> <a :href="`mailto:${to.address}`">{{ to.address }}</a
              ><br />
            </span>
          </td>
          <td>
            <b v-if="email.cc">{{ email.cc.name }}</b>
            <a v-if="email.cc" :href="`mailto:${email.cc.address}`">{{ email.cc.address }}</a>
          </td>
          <td>
            {{ email.templateData }}
          </td>
          <td>
            {{ email.description }}
          </td>
          <td>
            <key-value-presenter label="Sent" :value="$helpers.dateTimeString(email.queuedDate)" />
            <!-- <key-value-presenter label="Sent" :value="$helpers.dateTimeString(email.sentDate)" /> -->
          </td>
          <td>
            <div class="btn-group btn-group">
              <button class="btn btn-secondary" @click="resend(email.emailId)">Resend</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <p v-if="emails && emails.length === 0">No emails have been sent to this business.</p>
</template>

<script>
import {
  Subtitle,
  KeyValuePresenter,
  confirmType,
  ConfirmDialog,
  Card,
} from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
  name: 'SchemeBusinessEmails',
  components: {
    Subtitle,
    KeyValuePresenter,
    ConfirmDialog,
    Card,
  },
  data() {
    return {};
  },
  computed: {
    emails() {
      return this.$store.state.schemeBusinessEmail.emails;
    },
    model() {
      return this.$store.state.schemeBusiness.business;
    },
  },
  mounted() {
    this.$store.dispatch('schemeBusinessEmail/listEmails', {
      schemeId: this.$route.params.schemeId,
      schemeBusinessId: this.$route.params.schemeBusinessId,
    });
  },
  methods: {
    resend(emailId) {
      this.$store
        .dispatch('schemeBusinessEmail/listEmails', {
          schemeId: this.$route.params.schemeId,
          schemeBusinessId: this.$route.params.schemeBusinessId,
          emailId,
        })
        .then(() => {
          this.$refs.confirm.show({
            title: 'Sent',
            message: 'The email has been resent',
            confirmType: confirmType.okOnly,
          });
        });
    },
    toWorkflowStep(step) {
      switch (step) {
        case 0:
          return 'None';
        case 1:
          return 'Welcome';
        case 2:
          return '90 Day';
        case 3:
          return '180 Day';
        case 4:
          return 'Yearly';
        case 5:
          return 'Leave';
        default:
          return 'None';
      }
    },
  },
};
</script>
