import AuditQuestionsService from '../services/audit-questions-service';

export const auditQuestions = {
  namespaced: true,
  state: {
    questions: [],
    currentQuestionSet: {},
    pageNumber: 0,
    searchResult: {},
    lastSearchConfig: null,
    versionList: [],
    appData: null,
    searchModel: {
      searchTerm: null,
      previousSearchTerm: null,
    },
    advancedSearchIsOpen: false,
    advancedSearchModel: {
      formStage: null,
      formCategory: null,
      formName: null,
      formOutcomeType: null,
      projectType: null,
      measureCode: null,
    },
  },
  actions: {
    getAll({ commit }) {
      commit('setIsBusy', true, { root: true });
      commit('setAuditQuestions', []);
      return AuditQuestionsService.getAll()
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setAuditQuestions', result.model);
          return Promise.resolve(result.model);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    getVersionList({ commit }, id) {
      commit('setIsBusy', true, { root: true });
      commit('setVersionList', []);
      return AuditQuestionsService.getVersionList(id)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setVersionList', result.model);
          return Promise.resolve(result.model);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    getVersion({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      commit('setCurrentQuestionSet', {});
      return AuditQuestionsService.getVersion(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setCurrentQuestionSet', result.model);
          return Promise.resolve(result.model);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    getDraft({ commit }, id) {
      commit('setIsBusy', true, { root: true });
      commit('setCurrentQuestionSet', {});
      return AuditQuestionsService.getDraft(id)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setCurrentQuestionSet', result.model);
          return Promise.resolve(result.model);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    getReview({ commit }, id) {
      commit('setIsBusy', true, { root: true });
      commit('setCurrentQuestionSet', {});
      return AuditQuestionsService.getReview(id)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setCurrentQuestionSet', result.model);
          return Promise.resolve(result.model);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    getCurrent({ commit }, id) {
      commit('setIsBusy', true, { root: true });
      commit('setCurrentQuestionSet', {});
      return AuditQuestionsService.getCurrent(id)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setCurrentQuestionSet', result.model);
          return Promise.resolve(result.model);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    post({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return AuditQuestionsService.post(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('addAuditQuestion', result.model);
          commit('setCurrentQuestionSet', result.model);
          return Promise.resolve(result.model);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    addQuestion({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return AuditQuestionsService.addQuestion(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setCurrentQuestionSet', result.model);
          return Promise.resolve(result.model);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    put({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return AuditQuestionsService.put(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setCurrentQuestionSet', result.model);
          return Promise.resolve(result.model);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    delete({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return AuditQuestionsService.delete(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          return Promise.resolve(result.model);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    publish({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return AuditQuestionsService.publish(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setCurrentQuestionSet', result.model);
          return Promise.resolve(result.model);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    review({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return AuditQuestionsService.review(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setCurrentQuestionSet', result.model);
          return Promise.resolve(result.model);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    getAppData({ commit }) {
      return AuditQuestionsService.appData()
        .then((result) => {
          commit('setAppData', result);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    setNew({ commit }) {
      commit('setCurrentQuestionSet', {
        formName: '',
      });
    },
    import({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return AuditQuestionsService.import(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setAuditQuestions', result.model);
          return Promise.resolve(result.model);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    search({ commit }, data) {
      commit('setLastSearchConfig', data);
      return AuditQuestionsService.search(data).then(
        (result) => {
          commit('setAuditQuestionsSearchResult', result);
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    searchAdvanced({ commit }, data) {
      data.type = 'advanced';
      commit('setLastSearchConfig', data);
      return AuditQuestionsService.advancedSearch(data).then(
        (result) => {
          commit('setAuditQuestionsSearchResult', result);
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    setAppData(state, appData) {
      state.appData = appData;
    },
    setAuditQuestions(state, newAuditQuestions) {
      state.questions = newAuditQuestions;
    },
    setAuditQuestionsSearchResult(state, result) {
      state.searchResult = result;
    },
    setVersionList(state, newAuditQuestions) {
      state.versionList = newAuditQuestions;
    },
    addAuditQuestion(state, newAuditQuestion) {
      state.questions.push(newAuditQuestion);
    },
    setCurrentQuestionSet(state, q) {
      state.currentQuestionSet = q;
      // for some reason a null value really screws with the multi-select when navigating between question sets
      state.currentQuestionSet.projectType = state.currentQuestionSet.projectType || [];
    },
    setLastSearchConfig(state, searchconfig) {
      state.lastSearchConfig = searchconfig;
    },
    setSearchPageNumber(state, newPageNumber) {
      state.pageNumber = newPageNumber;
    },
    setSearchModel(state, newValue) {
      state.searchModel = newValue;
    },
    setSearchTerm(state, newValue) {
      state.searchModel.searchTerm = newValue;
    },
    setPreviousSearchTerm(state, newValue) {
      state.searchModel.previousSearchTerm = newValue;
    },
    setAdvancedSearchIsOpen(state, newValue) {
      state.advancedSearchIsOpen = newValue;
    },
    setAdvancedSearchModel(state, newValue) {
      state.advancedSearchModel = newValue;
    },
  },
};
