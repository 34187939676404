<template>
  <confirm-dialog ref="confirm"></confirm-dialog>
  <subtitle>Reviews</subtitle>

  <key-value-presenter-hyperlinked label="Viewing" :href="lapUrl" :value="lapUrl" class="mt-4" />
  <iframe :src="lapUrl" width="100%" height="500" frameborder="0"> </iframe>
</template>

<script>
import {
  Subtitle,
  KeyValuePresenterHyperlinked,
} from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
  name: 'SchemeBusinessReviews',
  components: {
    Subtitle,
    KeyValuePresenterHyperlinked,
  },

  computed: {
    model() {
      return this.$store.state.schemeBusiness.business;
    },
    lapUrl() {
      return `https://www.reputations.net/trustmark/rating-panel/${this.model.schemeBusiness.tmln}-${this.model.schemeBusiness.schemeBusinessId}`;
    },
  },
  mounted() {},
  methods: {},
};
</script>
