<template>
  <confirm-dialog ref="confirmDialog" />
  <div class="btn-toolbar mb-3" role="toolbar">
    <div class="row w-100">
      <div class="col-sm-12 col-md-9 col-lg-6">
        <div class="input-group" :class="{ disabled: disabled || loading }">
          <div id="btnGroupAddon" class="input-group-text bg-transparent border-end-0">
            <font-awesome-icon icon="magnifying-glass" />
          </div>
          <input
            v-model="searchModel.searchTerm"
            type="text"
            class="form-control border-start-0"
            placeholder="Find user"
          />

          <smart-button :disabled="disabled" :loading="loading" :click="search">
            Search
          </smart-button>
          <smart-button :loading="false" :disabled="!resultsModel || disabled" :click="clear"
            >Clear
          </smart-button>
        </div>
      </div>
    </div>
  </div>

  <div v-if="searchError" class="alert alert-danger" role="alert">
    There was a problem with the search {{ searchError }}.
  </div>

  <div
    v-if="resultsModel && resultsModel.length === 0 && !loading"
    class="alert alert-warning"
    role="alert"
  >
    There are no matching results for this search.
  </div>

  <div class="w-100">
    <table v-if="resultsModel && resultsModel.length > 0" class="table table-hover">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Email</th>
          <th scope="col">Last sign-in</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="resultsModel.length === 0">
          <td colspan="4">You don't have any users</td>
        </tr>
        <tr v-for="user in resultsModel" :key="user.accountId">
          <td>{{ user.firstName }} {{ user.lastName }}</td>
          <td>{{ user.accountId }}</td>
          <td>
            {{ $helpers.dateTimeString(user.lastSignIn) }}
          </td>
          <td>
            <div class="btn-group me-2">
              <router-link
                :to="`/scheme/${schemeId}/edit/users/${user.accountId}`"
                class="btn btn-outline-secondary"
              >
                View
              </router-link>
              <button
                v-if="!user.isDisabled && isSchemeUserAdmin"
                type="button"
                class="btn btn-outline-danger"
                @click="confirmDisableAccount(user)"
              >
                Disable
              </button>
              <button
                v-if="user.isDisabled && isSchemeUserAdmin"
                type="button"
                class="btn btn-outline-secondary"
                @click="confirmEnableAccount(user)"
              >
                Enable
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div v-if="!resultsModel && !loading" class="alert alert-info" role="alert">
    Your search results will be listed here.
  </div>
</template>
<script>
import {
  confirmType,
  SmartButton,
  ConfirmDialog,
} from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
  name: 'UserTable',
  components: {
    SmartButton,
    ConfirmDialog,
  },
  data() {
    return {
      loading: false,
      searchModel: {
        searchTerm: null,
        previousSearchTerm: null,
        pageNumber: 0,
      },
      searchError: null,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.auth.account;
    },
    isSchemeUserAdmin() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('SchemeUserAdmin')
        : false;
    },
    resultsModel() {
      function compareFn(a, b) {
        const nameA = (a.firstName + a.lastName).toString().trim().toUpperCase();
        const nameB = (b.firstName + b.lastName).toString().trim().toUpperCase();
        const cmp = nameB > nameA ? -1 : 0;
        return nameA > nameB ? 1 : cmp;
      }

      if (this.$store.state.schemeUser.users) {
        const unfilteredClone = JSON.parse(JSON.stringify(this.$store.state.schemeUser.users));

        if (!this.searchModel.searchTerm || !this.searchModel.searchTerm.length) {
          if (unfilteredClone) {
            unfilteredClone.sort(compareFn);
          }
          return unfilteredClone;
        }

        let filteredClone = JSON.parse(JSON.stringify(unfilteredClone));

        filteredClone = filteredClone.filter(
          (x) =>
            x.firstName.toUpperCase().includes(this.searchModel.searchTerm.toUpperCase()) ||
            x.lastName.toUpperCase().includes(this.searchModel.searchTerm.toUpperCase()) ||
            x.accountId.toUpperCase().includes(this.searchModel.searchTerm.toUpperCase())
        );

        if (filteredClone) {
          filteredClone.sort(compareFn);
        }
        return filteredClone;
      }
      return [];
    },
    disabled() {
      return !this.searchModel.searchTerm || this.searchModel.searchTerm.length === 0;
    },
    schemeId() {
      return this.$route.params.schemeId;
    },
  },
  mounted() {
    this.search();
  },
  methods: {
    search() {
      this.searchError = null;
      this.loading = true;

      if (this.searchModel.searchTerm !== this.searchModel.previousSearchTerm) {
        this.searchModel.pageNumber = 0;
      }

      this.searchModel.previousSearchTerm = this.searchModel.searchTerm;

      this.$store
        .dispatch('schemeUser/get', this.$route.params.schemeId)
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.searchError = err.message;
        });
    },
    clear() {
      this.searchModel.searchTerm = '';
      this.search();
    },
    confirmDisableAccount(user) {
      this.$refs.confirmDialog
        .show({
          title: 'Disable Account',
          message: 'Are you sure you want to disable this account?',
          okButtonText: 'Disable',
          confirmType: confirmType.destructive,
        })
        .then((result) => {
          if (result) {
            this.disableAccount(user);
          }
        });
    },
    disableAccount(user) {
      this.$store
        .dispatch('schemeUser/disable', user)
        .then(() => {
          this.$store.dispatch('schemeUser/get', this.$route.params.schemeId).then(() => {
            this.loading = false;
          });
        })
        .catch((err) => {
          this.loading = false;
          this.searchError = err.message;
        });
    },
    confirmEnableAccount(user) {
      this.$refs.confirmDialog
        .show({
          title: 'Enable Account',
          message: 'Are you sure you want to enable this account?',
          okButtonText: 'Enable',
        })
        .then((result) => {
          if (result) {
            this.enableAccount(user);
          }
        });
    },
    enableAccount(user) {
      this.$store
        .dispatch('schemeUser/enable', user)
        .then(() => {
          this.$store.dispatch('schemeUser/get', this.$route.params.schemeId).then(() => {
            this.loading = false;
          });
        })
        .catch((err) => {
          this.loading = false;
          this.searchError = err.message;
        });
    },
  },
};
</script>
