import { createWebHistory, createRouter } from 'vue-router';
import logoutCleanup from './services/logout-cleanup';
import Login from './components/Login.vue';
import Register from './components/Register.vue';
import store from './store';
import SchemeRegister from './components/schemes/SchemesRegister.vue';
import RequestResetCode from './components/RequestResetCode.vue';
import ResetPassword from './components/ResetPassword.vue';

import Dashboard from './components/dashboard/Dashboard.vue';

import SchemeEditRouter from './components/schemes/edit/SchemesEditRouter.vue';
import SchemeEditAdvanced from './components/schemes/edit/SchemesEditAdvanced.vue';
import SchemeEditDetails from './components/schemes/edit/SchemesEditDetails.vue';
import SchemeEditRegistration from './components/schemes/edit/SchemesEditRegistration.vue';

import SchemeContactRouter from './components/schemes/edit/contacts/SchemesEditContactsRouter.vue';
import SchemeContactList from './components/schemes/edit/contacts/SchemesEditContactsList.vue';
import SchemeContactCreate from './components/schemes/edit/contacts/SchemesEditContactsCreate.vue';
import SchemeContactEdit from './components/schemes/edit/contacts/SchemesEditContactsEdit.vue';

import SchemeViewNoteRouter from './components/schemes/notes/SchemesViewNotesRouter.vue';
import SchemeViewNoteList from './components/schemes/notes/SchemesViewNotesList.vue';

import SchemeNoteRouter from './components/schemes/edit/notes/SchemesEditNotesRouter.vue';
import SchemeNoteList from './components/schemes/edit/notes/SchemesEditNotesList.vue';
import SchemeNoteCreate from './components/schemes/edit/notes/SchemesEditNotesCreate.vue';
import SchemeNoteEdit from './components/schemes/edit/notes/SchemesEditNotesEdit.vue';

import SchemeBusinessNotesRouter from './components/schemeBusiness/notes/SchemeBusinessNotesRouter.vue';
import SchemeBusinessNotesList from './components/schemeBusiness/notes/SchemeBusinessNotesList.vue';
import SchemeBusinessNotesCreate from './components/schemeBusiness/notes/SchemeBusinessNotesCreate.vue';
import SchemeBusinessNotesEdit from './components/schemeBusiness/notes/SchemeBusinessNotesEdit.vue';

import SchemeTradeRouter from './components/schemes/edit/trades/SchemesEditTradesRouter.vue';
import SchemeTradeList from './components/schemes/edit/trades/SchemesEditTradesList.vue';
import SchemeTradeCreate from './components/schemes/edit/trades/SchemesEditTradesCreate.vue';
import SchemeTradeEdit from './components/schemes/edit/trades/SchemesEditTradesEdit.vue';

import TradeRouter from './components/trades/TradesRouter.vue';
import TradeList from './components/trades/TradesList.vue';
import TradeCreate from './components/trades/TradesCreate.vue';
import TradeEdit from './components/trades/TradesEdit.vue';

import ListApplications from './components/applications/ApplicationsList.vue';
import ViewApplication from './components/applications/ApplicationsView.vue';
import EditApplication from './components/applications/ApplicationEdit.vue';
import ApplicationTradingStandards from './components/applications/ApplicationsTradingStandards.vue';

import TradingStandardsAdminView from './components/tradingStandards/TradingStandardsAdminView.vue';
import TradingStandardsYearView from './components/tradingStandards/TradingStandardsYearView.vue';

import TradingStandardsFeedbackView from './components/tradingStandardsFeedback/TradingStandardsFeedbackView.vue';
import TradingStandardsFeedbackEditView from './components/tradingStandardsFeedback/TradingStandardsFeedbackEditView.vue';

import RegistrationBillingList from './components/registrationBilling/RegistrationBillingList.vue';

import LicenceNumberList from './components/licenceNumber/LicenceNumberList.vue';
import LicenceView from './components/licenceNumber/View/LicenceNumberViewInformation.vue';
import LicenceRouter from './components/licenceNumber/View/LicenceNumberViewRouter.vue';
import LicenceLinkedBusinesses from './components/licenceNumber/View/LicenceNumberViewLinkedBusinesses.vue';
import LicenceTrades from './components/licenceNumber/View/LicenceNumberViewTrades.vue';
import LicenceCompliance from './components/licenceNumber/View/LicenceNumberViewCompliance.vue';
import LicenceRetrofitAccount from './components/licenceNumber/View/LicenceNumberViewRetrofitAccount.vue';
import LicenceRetrofitTopup from './components/licenceNumber/View/LicenceNumberViewRetrofitTopup.vue';
import LicenceRetrofitRefund from './components/licenceNumber/View/LicenceNumberViewRetrofitRefund.vue';

import SchemeBusinessContacts from './components/schemeBusiness/SchemeBusinessContacts.vue';
import SchemeBusinessOverview from './components/schemeBusiness/SchemeBusinessOverview.vue';
import SchemeBusinessRouter from './components/schemeBusiness/SchemeBusinessRouter.vue';
import SchemeBusinessTrades from './components/schemeBusiness/SchemeBusinessTradesAndRegistrations.vue';
import SchemeBusinessEmails from './components/schemeBusiness/SchemeBusinessEmails.vue';
import SchemeBusinessActivityLogs from './components/schemeBusiness/SchemeBusinessActivityLogs.vue';
import SchemeBusinessFormattedActivityLogs from './components/schemeBusiness/SchemeBusinessFormattedActivityLogs.vue';
import SchemeBusinessReviews from './components/schemeBusiness/SchemeBusinessReviews.vue';

import PendingLicenceActionList from './components/pendingLicenceAction/PendingLicenceActionList.vue';

import SoftwareProvidersList from './components/softwareProviders/SoftwareProvidersList.vue';
import SoftwareProviderView from './components/softwareProviders/SoftwareProviderView.vue';
import SoftwareProviderCreate from './components/softwareProviders/SoftwareProviderCreate.vue';

import SchemeUserRouter from './components/schemes/edit/user/UserRouter.vue';
import SchemeUserList from './components/schemes/edit/user/UserList.vue';
import SchemeUserView from './components/schemes/edit/user/UserView.vue';

const BusinessList = () => import('./components/businesses/BusinessList.vue');

const FundersRouter = () => import('./components/funders/FundersRouter.vue');
const FundersList = () => import('./components/funders/FundersList.vue');
const FundersRegister = () => import('./components/funders/FundersRegister.vue');
const FundersEditRouter = () => import('./components/funders/edit/FundersEditRouter.vue');
const FundersEditDetails = () => import('./components/funders/edit/FundersEditDetails.vue');
const FundersEditUsers = () => import('./components/funders/edit/FundersEditUsers.vue');
const FundersEditUsage = () => import('./components/funders/edit/FundersEditUsage.vue');
const FundersEditActivityLog = () => import('./components/funders/edit/FundersEditActivityLog.vue');
const FundersEditAdvanced = () => import('./components/funders/edit/FundersEditAdvanced.vue');
const FundersEditUser = () => import('./components/funders/edit/users/FundersEditUser.vue');
const FundersEditUserInvite = () =>
  import('./components/funders/edit/users/FundersEditUserInvite.vue');

const PropertyCheckerRouter = () => import('./components/propertyChecker/PropertyCheckerRouter.vue');
const PropertyCheckerUser = () => import('./components/propertyChecker/users/PropertyCheckerEditUser.vue');
const PropertyCheckerUserInvite = () => import('./components/propertyChecker/users/PropertyCheckerEditUserInvite.vue');
const PropertyCheckerUsers = () => import('./components/propertyChecker/PropertyCheckerEditUsers.vue');

// lazy-loaded
const SchemeList = () => import('./components/schemes/SchemesList.vue');
const SchemeViewRouter = () => import('./components/schemes/SchemeViewRouter.vue');
const SchemeViewOverview = () => import('./components/schemes/SchemeViewOverview.vue');
const SchemeViewDetails = () => import('./components/schemes/SchemeViewDetails.vue');
const SchemeViewTrades = () => import('./components/schemes/SchemeViewTrades.vue');
const SchemeViewStatistics = () => import('./components/schemes/SchemeViewStatistics.vue');

const SiteSettingsOverview = () => import('./components/site/SiteSettingsOverview.vue');
const SiteSettingsRouter = () => import('./components/site/SiteSettingsRouter.vue');
const EditSiteSettings = () => import('./components/site/EditSiteSettings.vue');

const AuditQuestionsOverview = () =>
  import('./components/auditQuestions/AuditQuestionsOverview.vue');
const AuditQuestionsImport = () => import('./components/auditQuestions/AuditQuestionsImport.vue');
const AuditQuestionsRouter = () => import('./components/auditQuestions/AuditQuestionsRouter.vue');
const EditAuditQuestions = () => import('./components/auditQuestions/EditAuditQuestions.vue');
const EditSingleAuditQuestion = () =>
  import('./components/auditQuestions/EditSingleAuditQuestion.vue');
const NewAuditQuestionSet = () => import('./components/auditQuestions/NewAuditQuestionSet.vue');
const AuditQuestionsVersionList = () =>
  import('./components/auditQuestions/AuditQuestionsVersionList.vue');

const BackOfficeUserList = () => import('./components/backofficeUsers/BackOfficeUserList.vue');
const BackOfficeUserEdit = () => import('./components/backofficeUsers/BackOfficeUserEdit.vue');
const BackOfficeUserInvite = () => import('./components/backofficeUsers/BackOfficeUserInvite.vue');
const BackOfficeUserView = () => import('./components/backofficeUsers/BackOfficeUserView.vue');
const BackOfficeUserAccountEvents = () => import('./components/backofficeUsers/UserAccountEventsView.vue');

const SchemeUsageOverview = () => import('./components/schemeUsage/SchemeUsageOverview.vue');
const SchemeUsageRouter = () => import('./components/schemeUsage/SchemeUsageRouter.vue');

const BlockedEmailsOverview = () => import('./components/blockedEmails/BlockedEmailsOverview.vue');
const BlockedEmailsRouter = () => import('./components/blockedEmails/BlockedEmailsRouter.vue');

const Insights = () => import('./components/insights/Insights.vue');

const MatrixTypesOverview = () => import('./components/matrixTypes/MatrixTypesOverview.vue');
const MatrixTypesRouter = () => import('./components/matrixTypes/MatrixTypesRouter.vue');
const MatrixTypesViewOverview = () =>
  import('./components/matrixTypes/view/MatrixTypesViewOverview.vue');
const MatrixTypesViewRouter = () =>
  import('./components/matrixTypes/view/MatrixTypesViewRouter.vue');
const MatrixTypesViewCosts = () => import('./components/matrixTypes/view/MatrixTypesViewCosts.vue');
const MatrixTypesViewRequiredDocuments = () =>
  import('./components/matrixTypes/view/MatrixTypesViewRequiredDocuments.vue');
const MatrixTypesViewRestrictedTrades = () =>
  import('./components/matrixTypes/view/MatrixTypesViewRestrictedTrades.vue');
const MatrixTypesViewLocalAuthority = () =>
  import('./components/matrixTypes/view/MatrixTypesViewLocalAuthority.vue');
const MatrixTypesViewQuestions = () =>
  import('./components/matrixTypes/view/MatrixTypesViewQuestions.vue');
const MatrixTypesViewFinancialProducts = () =>
  import('./components/matrixTypes/view/MatrixTypesViewFinancialProducts.vue');

const MeasuresOverview = () => import('./components/measures/MeasuresOverview.vue');
const MeasuresRouter = () => import('./components/measures/MeasuresRouter.vue');
const MeasuresViewRouter = () => import('./components/measures/view/MeasuresViewRouter.vue');
const MeasuresViewOverview = () => import('./components/measures/view/MeasuresViewOverview.vue');

const BusinessReports = () => import('./components/businesses/reports/BusinessReports.vue');
const BusinessReportsList = () => import('./components/businesses/reports/BusinessReportsList.vue');
const BusinessReportsNew = () => import('./components/businesses/reports/BusinessReportsNew.vue');


const EnergySuppliersRouter = () => import('./components/energySuppliers/EnergySuppliersRouter.vue');
const EnergySuppliersOverview = () => import('./components/energySuppliers/EnergySuppliersOverview.vue');
const EnergySuppliersViewRouter = () => import('./components/energySuppliers/view/EnergySuppliersViewRouter.vue');
const EnergySuppliersViewOverview = () => import('./components/energySuppliers/view/EnergySuppliersViewOverview.vue');
const EnergySuppliersViewNotifications = () => import('./components/energySuppliers/view/EnergySuppliersViewNotifications.vue');

const performLogoutWithLoginRedirect = () => {
  logoutCleanup();
};

const routes = [
  {
    path: '/',
    name: 'home',
    component: Dashboard,
    meta: {
      title: 'Dashboard',
      schemes: false,
    },
  },
  {
    path: '/register',
    component: Register,
    meta: {
      title: 'Register',
    },
  },
  {
    path: '/forgot',
    component: RequestResetCode,
    meta: {
      title: 'Forgot',
    },
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    meta: {
      title: 'Reset',
    },
  },
  {
    path: '/backoffice-users',
    name: 'backofficeUsers',
    component: BackOfficeUserList, // lazy-loaded
    meta: {
      title: 'BackOffice Users',
      users: true,
    },
  },
  {
    path: '/backoffice-users/invite',
    name: 'backofficeUserInvite',
    component: BackOfficeUserInvite, // lazy-loaded
    meta: {
      title: 'BackOffice Invite User',
      users: true,
    },
  },
  {
    path: '/backoffice-user/:backofficeUserId',
    name: 'userView',
    component: BackOfficeUserView, // lazy-loaded
    meta: {
      title: 'View BackOffice User',
      users: true,
    },
  },
  {
    path: '/backoffice-user/:backofficeUserId/events',
    name: 'userAccountEvents',
    component: BackOfficeUserAccountEvents, // lazy-loaded
    meta: {
      title: 'User Account Events',
      users: true,
    },
  },
  {
    path: '/backoffice-user/:backofficeUserId/edit',
    name: 'userEdit',
    component: BackOfficeUserEdit, // lazy-loaded
    meta: {
      title: 'Edit BackOffice User',
      users: true,
    },
  },
  {
    path: '/site-settings/',
    component: SiteSettingsRouter,
    meta: {
      title: 'Settings',
      siteSettings: true,
    },
    children: [
      {
        path: '',
        component: SiteSettingsOverview,
      },
      {
        path: 'edit',
        component: EditSiteSettings,
      },
      {
        path: 'details',
        component: SchemeViewDetails, // lazy-loaded
      },
    ],
  },
  {
    path: '/audit-questions/',
    component: AuditQuestionsRouter,
    meta: {
      title: 'Audit Questions',
      auditQuestions: true,
    },
    children: [
      {
        path: '',
        component: AuditQuestionsOverview,
      },
      {
        path: 'import',
        component: AuditQuestionsImport,
      },
      {
        path: 'new',
        component: NewAuditQuestionSet,
      },
      {
        path: ':auditQuestionSetId/draft',
        name: 'audit-question-set',
        component: EditAuditQuestions,
      },
      {
        path: ':auditQuestionSetId/draft/:questionId',
        name: 'audit-question-set-question',
        component: EditSingleAuditQuestion,
      },
      {
        path: ':auditQuestionSetId/review',
        name: 'audit-question-set-review',
        component: EditAuditQuestions,
      },
      {
        path: ':auditQuestionSetId/review/:questionId',
        name: 'audit-question-set-question-review',
        component: EditSingleAuditQuestion,
      },
      {
        path: ':auditQuestionSetId/current',
        name: 'audit-question-set-current',
        component: EditAuditQuestions,
      },
      {
        path: ':auditQuestionSetId/current/:questionId',
        name: 'audit-question-set-question-current',
        component: EditSingleAuditQuestion,
      },
      {
        path: ':auditQuestionSetId/versions',
        name: 'audit-question-set-versions',
        component: AuditQuestionsVersionList,
      },
      {
        path: ':auditQuestionSetId/versions/:version',
        name: 'audit-question-set-version',
        component: EditAuditQuestions,
      },
      {
        path: ':auditQuestionSetId/versions/:version/:questionId',
        name: 'audit-question-set-question-version',
        component: EditSingleAuditQuestion,
      },
    ],
  },
  {
    path: '/matrix-types/',
    component: MatrixTypesRouter,
    meta: {
      title: 'Matrix Types',
      matrixTypes: true,
    },
    children: [
      {
        path: '',
        component: MatrixTypesOverview,
      },
    ],
  },
  {
    path: '/measure-types/',
    component: MeasuresRouter,
    meta: {
      title: 'Measure Types',
      measures: true,
    },
    children: [
      {
        path: '',
        name: 'measures-search',
        component: MeasuresOverview,
      },
      {
        path: ':code',
        component: MeasuresViewRouter,
        children: [
          {
            path: '',
            component: MeasuresViewOverview,
          },
        ],
      },
    ],
  },
  {
    path: '/energy-suppliers/',
    component: EnergySuppliersRouter,
    meta: {
      title: 'Energy Suppliers',
      energySuppliers: true,
    },
    children: [
      {
        path: '',
        name: 'energy-suppliers-search',
        component: EnergySuppliersOverview,
      },
      {
        path: ':energySupplierId',
        component: EnergySuppliersViewRouter,
        children: [
          {
            path: '',
            component: EnergySuppliersViewOverview,
          },
          {
            path: 'notifications',
            component: EnergySuppliersViewNotifications,
          },
        ],
      },
    ],
  },
  {
    path: '/matrix-types/:type',
    component: MatrixTypesViewRouter,
    meta: {
      title: 'Matrix Type',
      matrixTypes: true,
    },
    children: [
      {
        path: '',
        component: MatrixTypesViewOverview, // lazy-loaded
      },
      {
        path: 'costs',
        component: MatrixTypesViewCosts, // lazy-loaded
      },
      {
        path: 'required-documents',
        component: MatrixTypesViewRequiredDocuments, // lazy-loaded
      },
      {
        path: 'restricted-trades',
        component: MatrixTypesViewRestrictedTrades, // lazy-loaded
      },
      {
        path: 'local-authority',
        component: MatrixTypesViewLocalAuthority, // lazy-loaded
      },
      {
        path: 'questions',
        component: MatrixTypesViewQuestions, // lazy-loaded
      },
      {
        path: 'financial-products',
        component: MatrixTypesViewFinancialProducts, // lazy-loaded
      },
    ],
  },
  {
    path: '/scheme-usage/',
    component: SchemeUsageRouter,
    meta: {
      title: 'Scheme Usage',
    },
    children: [
      {
        path: '',
        component: SchemeUsageOverview,
      },
    ],
  },
  {
    path: '/blocked-emails/',
    component: BlockedEmailsRouter,
    meta: {
      title: 'Blocked Emails',
      blockedEmails: true,
    },
    children: [
      {
        path: '',
        component: BlockedEmailsOverview,
      },
    ],
  },
  {
    path: '/login',
    component: Login,
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter: performLogoutWithLoginRedirect,
  },
  {
    path: '/schemes',
    component: SchemeList,
    meta: {
      title: 'Schemes',
      schemes: true,
    },
  },
  {
    path: '/scheme/:schemeId',
    component: SchemeViewRouter,
    meta: {
      title: 'Scheme',
      schemes: true,
    },
    children: [
      {
        path: '',
        component: SchemeViewOverview, // lazy-loaded
      },
      {
        path: 'details',
        component: SchemeViewDetails, // lazy-loaded
      },
      {
        path: 'trades',
        component: SchemeViewTrades, // lazy-loaded
      },
      {
        path: 'statistics',
        component: SchemeViewStatistics, // lazy-loaded
      },
      {
        path: 'notes',
        component: SchemeViewNoteRouter, // lazy-loaded
        children: [
          {
            path: '',
            component: SchemeViewNoteList, // lazy-loaded
          },
        ],
      },
    ],
  },
  {
    path: '/scheme/:schemeId/edit',
    name: 'schemeEdit',
    component: SchemeEditRouter, // lazy-loaded
    meta: {
      title: 'Edit Scheme',
      schemes: true,
    },
    children: [
      {
        path: 'advanced',
        component: SchemeEditAdvanced, // lazy-loaded
      },
      {
        path: '',
        component: SchemeEditDetails, // lazy-loaded
        meta: {
          displayActionButtons: true,
        },
      },
      {
        path: 'contacts',
        component: SchemeContactRouter, // lazy-loaded
        children: [
          {
            path: '',
            component: SchemeContactList, // lazy-loaded
          },
          {
            path: 'create',
            component: SchemeContactCreate, // lazy-loaded
          },
          {
            path: 'edit/:contactId',
            component: SchemeContactEdit, // lazy-loaded
          },
        ],
      },
      {
        path: 'notes',
        component: SchemeNoteRouter, // lazy-loaded
        children: [
          {
            path: '',
            component: SchemeNoteList, // lazy-loaded
          },
          {
            path: 'create',
            component: SchemeNoteCreate, // lazy-loaded
          },
          {
            path: 'edit/:noteId',
            component: SchemeNoteEdit, // lazy-loaded
          },
        ],
      },
      {
        path: 'scheme-trades',
        component: SchemeTradeRouter, // lazy-loaded
        children: [
          {
            path: '',
            component: SchemeTradeList, // lazy-loaded
          },
          {
            path: 'create',
            component: SchemeTradeCreate, // lazy-loaded
          },
          {
            path: 'edit/:tradeCode',
            component: SchemeTradeEdit, // lazy-loaded
          },
        ],
      },
      {
        path: 'registration',
        component: SchemeEditRegistration, // lazy-loaded
        meta: {
          displayActionButtons: true,
        },
      },
      {
        path: 'users',
        component: SchemeUserRouter,
        children: [
          {
            path: '',
            component: SchemeUserList,
          },
          {
            path: ':userid',
            component: SchemeUserView,
          },
        ],
      },
    ],
  },
  {
    path: '/trades',
    component: TradeRouter, // lazy-loaded
    children: [
      {
        path: '',
        component: TradeList, // lazy-loaded
        meta: {
          title: 'Trades',
          trades: true,
        },
      },
      {
        path: 'create',
        component: TradeCreate, // lazy-loaded
        meta: {
          title: 'Create Trades',
          trades: true,
        },
      },
      {
        path: 'edit/:tradeId',
        component: TradeEdit, // lazy-loaded
        meta: {
          title: 'Edit Trades',
          trades: true,
        },
      },
    ],
  },
  {
    path: '/schemes/register',
    name: 'scheme-register',
    component: SchemeRegister, // lazy-loaded
    meta: {
      title: 'Register Scheme',
      schemes: true,
    },
  },
  {
    path: '/trades',
    component: TradeList,
    meta: {
      title: 'Trades',
      trades: true,
    },
  },
  {
    path: '/applications',
    component: ListApplications, // lazy-loaded
    meta: {
      title: 'Applications',
      applications: true,
    },
  },
  {
    path: '/applications/:applicationRef',
    component: ViewApplication,
    meta: {
      title: 'View Application',
      applications: true,
    },
  },
  {
    path: '/applications/:applicationRef/edit',
    component: EditApplication,
    meta: {
      title: 'Edit Application',
      applications: true,
    },
  },
  {
    path: '/applications/:applicationRef/trading-standards',
    component: ApplicationTradingStandards,
    meta: {
      title: 'View Application',
      applications: true,
    },
  },
  {
    path: '/tradingstandards',
    component: TradingStandardsAdminView,
    meta: {
      title: 'View Trading Standards',
      tradingStandardsadmin: true,
    },
  },
  {
    path: '/tradingstandards/:tmln/:renewalYear',
    component: TradingStandardsYearView,
    meta: {
      title: 'Edit Trading Standards Application',
      tradingStandardsadmin: true,
    },
  },
  {
    path: '/trading-standards-feedback',
    component: TradingStandardsFeedbackView,
    meta: {
      title: 'View Trading Standards',
      tradingStandardsfeedback: true,
    },
  },
  {
    path: '/trading-standards-feedback/:tmln/:renewalYear',
    component: TradingStandardsFeedbackEditView,
    meta: {
      title: 'Edit Trading Standards Application',
      tradingStandardsfeedback: true,
    },
  },
  {
    path: '/registrations',
    component: RegistrationBillingList, // lazy-loaded
    meta: {
      title: 'Registration Billing',
      registrations: true,
    },
  },
  {
    path: '/tmlns',
    component: LicenceNumberList, // lazy-loaded
    meta: {
      title: 'Licence Numbers',
      tmlns: true,
    },
  },  
  {
    path: '/tmlns/users/:backofficeUserId/events',
    name: 'retrofitUserAccountEvents',
    component: BackOfficeUserAccountEvents, // lazy-loaded
    meta: {
      title: 'User Account Events',
      tmlns: true,
    },
  },
  {
    path: '/tmlns/:tmBusinessId/',
    name: 'scheme-edit',
    component: LicenceRouter, // lazy-loaded
    meta: {
      title: 'License',
      tmlns: true,
    },
    children: [
      {
        path: '',
        component: LicenceView, // lazy-loaded
      },
      {
        path: 'Linked-businesses',
        component: LicenceLinkedBusinesses, // lazy-loaded
      },
      {
        path: 'trades',
        component: LicenceTrades, // lazy-loaded
      },
      {
        name: 'retrofit-account',
        path: 'retrofit-account',
        component: LicenceRetrofitAccount,
      },
      {
        name: 'compliance',
        path: 'compliance',
        component: LicenceCompliance,
      },
      {
        path: 'retrofit-topup',
        component: LicenceRetrofitTopup,
      },

      {
        path: 'retrofit-refund',
        component: LicenceRetrofitRefund,
      },
    ],
  },
  {
    path: '/businesses',
    name: 'businesses',
    component: BusinessList, // lazy-loaded
    meta: {
      title: 'Businesses',
      business: true,
    },
    children: [
      {
        path: 'reports',
        component: BusinessReports,
        children: [
          {
            path: '',
            component: BusinessReportsList, // lazy-loaded
            meta: {
              exports: true,
              list: true,
            },
          },
          {
            path: 'new',
            component: BusinessReportsNew, // lazy-loaded
            meta: {
              exports: true,
              new: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/funders',
    name: 'funders',
    component: FundersRouter, // lazy-loaded
    meta: {
      title: 'Funders',
      funders: true,
    },
    children: [
      {
        path: '',
        component: FundersList,
      },
      {
        path: 'register',
        component: FundersRegister,
      },
      {
        path: ':funderId',
        component: FundersEditRouter,
        children: [
          {
            path: '',
            redirect: { name: 'funderDetails' },
          },
          {
            path: 'details',
            name: 'funderDetails',
            component: FundersEditDetails,
            meta: {
              displayActionButtons: true,
            },
          },
          {
            path: 'advanced',
            component: FundersEditAdvanced,
          },
          {
            path: 'users',
            component: FundersEditUsers,
          },
          {
            path: 'users/:accountId',
            component: FundersEditUser,
          },
          {
            path: 'users/invite',
            component: FundersEditUserInvite,
          },
          {
            path: 'usage',
            component: FundersEditUsage,
          },
          {
            path: 'activity-log',
            component: FundersEditActivityLog,
          },
        ],
      },
    ],
  },    
  {
    path: '/funders/:funderId/users/:backofficeUserId/events',
    name: 'fundersUserAccountEvents',
    component: BackOfficeUserAccountEvents, // lazy-loaded
    meta: {
      title: 'User Account Events',
      funders: true,
    },
  },
  {
    path: '/property-checker',
    name: 'property-checker',
    component: PropertyCheckerRouter,
    meta: {
      propertyCheckerUsers: true,
    },
    children: [
      {
        path: '',
        redirect: { name: 'propertyCheckerUsers' },
      },
      {
        path: 'users',
        name: 'propertyCheckerUsers',
        component: PropertyCheckerUsers,
      },
      {
        path: 'users/invite',
        component: PropertyCheckerUserInvite,
      },
      {
        path: 'users/:accountId',
        component: PropertyCheckerUser,
      },
    ],
  },    
  {
    path: '/property-checker/users/:backofficeUserId/events',
    name: 'propertyCheckerUserAccountEvents',
    component: BackOfficeUserAccountEvents, // lazy-loaded
    meta: {
      title: 'User Account Events',
      propertyCheckerUsers: true,
    },
  },
  {
    path: '/scheme-business/:schemeId/:schemeBusinessId/',
    name: 'Scheme Business',
    component: SchemeBusinessRouter, // lazy-loaded
    meta: {
      title: 'Scheme Business',
      business: true,
    },
    children: [
      {
        path: '',
        component: SchemeBusinessOverview, // lazy-loaded
      },
      {
        path: 'trades',
        component: SchemeBusinessTrades, // lazy-loaded
      },
      {
        path: 'contacts',
        component: SchemeBusinessContacts, // lazy-loaded
      },
      {
        path: 'emails',
        component: SchemeBusinessEmails, // lazy-loaded
      },
      {
        path: 'formatted-activity-logs',
        component: SchemeBusinessFormattedActivityLogs, // lazy-loaded
      },
      {
        path: 'raw-activity-logs',
        component: SchemeBusinessActivityLogs, // lazy-loaded
      },
      {
        path: 'reviews',
        component: SchemeBusinessReviews, // lazy-loaded
      },
      {
        path: 'notes',
        component: SchemeBusinessNotesRouter, // lazy-loaded
        children: [
          {
            path: '',
            component: SchemeBusinessNotesList, // lazy-loaded
          },
          {
            path: 'create',
            component: SchemeBusinessNotesCreate, // lazy-loaded
          },
          {
            path: 'edit/:noteId',
            component: SchemeBusinessNotesEdit, // lazy-loaded
          },
        ],
      },
    ],
  },
  {
    path: '/pending-licences',
    component: PendingLicenceActionList, // lazy-loaded
    meta: {
      pendingLicences: true,
    },
  },
  {
    path: '/pending-licences/:schemeId/:schemeBusinessId/',
    name: 'Pending Licence Action',
    component: SchemeBusinessRouter, // lazy-loaded
    meta: {
      title: 'Pending Licence Action',
      pendingLicences: true,
    },
    children: [
      {
        path: '',
        component: SchemeBusinessOverview, // lazy-loaded
      },
      {
        path: 'trades',
        component: SchemeBusinessTrades, // lazy-loaded
      },
      {
        path: 'contacts',
        component: SchemeBusinessContacts, // lazy-loaded
      },
      {
        path: 'emails',
        component: SchemeBusinessEmails, // lazy-loaded
      },
      {
        path: 'formatted-activity-logs',
        component: SchemeBusinessFormattedActivityLogs, // lazy-loaded
      },
      {
        path: 'raw-activity-logs',
        component: SchemeBusinessActivityLogs, // lazy-loaded
      },
      {
        path: 'reviews',
        component: SchemeBusinessReviews, // lazy-loaded
      },
      {
        path: 'notes',
        component: SchemeBusinessNotesRouter, // lazy-loaded
        children: [
          {
            path: '',
            component: SchemeBusinessNotesList, // lazy-loaded
          },
          {
            path: 'create',
            component: SchemeBusinessNotesCreate, // lazy-loaded
          },
          {
            path: 'edit/:noteId',
            component: SchemeBusinessNotesEdit, // lazy-loaded
          },
        ],
      },
    ],
  },
  {
    path: '/software-providers',
    component: SoftwareProvidersList, // lazy-loaded
    meta: {
      title: 'Software Providers',
      softwareProviders: true,
    },
  },
  {
    path: '/software-provider/:softwareProviderId',
    component: SoftwareProviderView,
    meta: {
      title: 'View Software Provider',
      softwareProviders: true,
    },
  },
  {
    path: '/register-software-provider',
    component: SoftwareProviderCreate,
    meta: {
      title: 'Register Software Provider',
      softwareProviders: true,
    },
  },
  {
    path: '/insights',
    component: Insights,
    meta: {
      title: 'Insights',
      insights: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/forgot', '/reset-password'];
  const authRequired = !publicPages.includes(to.path);
  const isPublicPage = publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('account');
  // Collapse menu
  store.commit('setSidebarCollapsed', true);
  store.commit('setIsBusy', true, { root: true });

  // Redirect to login if not logged in and attempting to access route that requires permission
  if (authRequired && !loggedIn) {
    next('/login');
  } else if (isPublicPage && loggedIn) {
    localStorage.removeItem('account');
    window.location = to.path;
  } else if (!store.state.auth.appData && loggedIn) {
    store.dispatch('auth/getAppData').then(() => {
      const { roles } = JSON.parse(loggedIn);
      if (roles.length < 3 && roles.includes('TradingStandardsFeedback')) {
        next('/trading-standards-feedback');
      } else {
        next();
      }
    });
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  store.commit('setIsBusy', false, { root: true });
});

export default router;
