import { HTTP, authHeader } from './http-service';

class SchemeContactService {
  list(schemeId, schemeBusinessId) {
    return HTTP.post(
      `/schemeBusinessEmail/business/${schemeId}/${schemeBusinessId}`,
      {},
      {
        headers: authHeader(),
      }
    ).then((response) => {
      return response.data;
    });
  }

  resend(schemeId, schemeBusinessId, emailId) {
    return HTTP.get(
      `/schemeBusinessEmail/${emailId}/business/${schemeId}/${schemeBusinessId}/resend`,
      {
        headers: authHeader(),
      }
    ).then((response) => {
      return response.data;
    });
  }
}

export default new SchemeContactService();
