<template>
  <subtitle>Retrofit Account</subtitle>
  <confirm-dialog ref="confirmDialog" />

  <div v-if="!model" class="col-sm-12 col-md-10">
    <card>
      <div class="form-check">
        <input
          id="addThirdPartySubmissions"
          :checked="addThirdPartySubmissions"
          class="form-check-input"
          type="checkbox"
          @change="toggleThirdPartySubmissions()"
        />
        <label class="form-check-label d-flex align-items-center" for="toggleAll">
          <span>Third Party Submissions</span>
        </label>
      </div>
      <button
        v-if="!tradingStandardsAppExpand"
        type="button"
        class="btn btn-primary mt-3"
        @click="createRetrofitAccount"
      >
        Create Retrofit Account
      </button>
    </card>
  </div>

  <div v-if="model" class="col-sm-12 col-md-10">
    <card>
      <div class="row">
        <div class="col-sm-12 col-md-8">
          <div class="form-label">
            <key-value-presenter
              label="Account Holder Business Name"
              :value="model.accountHolderName"
            />
          </div>
        </div>

        <div class="col-sm-12 col-md-4">
          <div class="form-label">
            <copy-key-value-presenter label="TMLN" :value="model.tmln" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-8">
          <div class="form-label">
            <copy-key-value-presenter
              label="Retrofit Account Id"
              :value="model.retrofitAccountId"
            />
          </div>
        </div>

        <div class="col-sm-12 col-md-4">
          <div class="form-label">
            <key-value-presenter
              label="Created"
              :value="$helpers.dateTimeString(model.createdAt)"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-8">
          <div class="form-label">
            <b>Wallet Balance: </b> {{ $helpers.currencyFormat(model.walletBalance) }}
          </div>
        </div>

        <div class="col-sm-12 col-md-4">
          <div class="form-label">
            <key-value-presenter label="Created By" :value="model.createdBy" :hide-if-null="true" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-8">
          <div class="form-label">
            <key-value-presenter label="VAT Number" :value="model.vatNumber" :hide-if-null="true" />
          </div>
        </div>

        <div class="col-sm-12 col-md-4">
          <div class="form-label">
            <key-value-presenter
              label="Updated"
              :value="$helpers.dateTimeString(model.updatedAt, 'Never')"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-8">
          <div class="form-label">
            <b>Products: </b>
            <div v-for="p in model.products" :key="p"><check-or-cross :state="true" /> {{ p }}</div>
          </div>
        </div>

        <div class="col-sm-12 col-md-4">
          <div class="form-label">
            <key-value-presenter label="Updated By" :value="model.updatedBy" :hide-if-null="true" />
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-sm-12 col-md-8">
          <div class="form-label">
            <b>Third Party Access: </b>
            <div v-for="p in model.thirdPartyAccess" :key="p.retrofitAccountId">
              <check-or-cross :state="true" /> {{ p.accountHolderName }} -
              <span>Granted on {{ $helpers.dateTimeString(p.dateGranted, 'Never') }}. </span>
              <span>(Retrofit Account Id:{{ p.retrofitAccountId }})</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col d-flex">
          <router-link
            class="btn btn-primary"
            type="button"
            :to="`/tmlns/${trustmarkBusinessId}/retrofit-topup`"
          >
            <span>Topup Account</span>
          </router-link>
          <router-link
            class="btn btn-primary ml-2"
            type="button"
            :to="`/tmlns/${trustmarkBusinessId}/retrofit-refund`"
          >
            <span>Refund Account Wallet</span>
          </router-link>
        </div>
      </div>
    </card>

    <card v-if="licence && licence.status === 2" label="Login Lock">
      <div class="row">
        <div class="col-sm-12 col-md-8">
          <div v-if="model.loginLocked" class="alert alert-danger" role="alert">
            <b>This Retrofit Account is locked, users are restricted from logging in.</b>
            <button
              type="button"
              class="btn btn-outline-success btn-sm"
              @click="onDisableLoginLock(model)">
              Disable Login Lock
            </button>

          </div>
          <div v-else class="alert alert-info" role="alert">
            <b>This Retrofit Account is not locked, users can still login and access data.</b>
            <button
              type="button"
              class="btn btn-outline-danger btn-sm"
              @click="onEnableLoginLock(model)">
              Enable Login Lock
            </button>
          </div>
          <div v-if="model.loginLockHistory">
            <div v-for="history in model.loginLockHistory" :key="history">
              <span>{{ history.description }}</span>
            </div>
          </div>
        </div>
      </div>
    </card>

    <licence-number-view-retrofit-account-notifications v-if="availableRoles" />

    <card v-if="model.billingAddress" label="Billing Address">
      <div class="row">
        <div class="col-sm-12 col-md-8">
          <div class="form-label">
            <conditional-br
              ><b>Company Name:</b> {{ model.billingAddress.companyName }}</conditional-br
            >
            <conditional-br><b>Address:</b></conditional-br>
            <conditional-br>{{ model.billingAddress.address1 }}</conditional-br>
            <conditional-br>{{ model.billingAddress.address2 }}</conditional-br>
            <conditional-br>{{ model.billingAddress.address3 }}</conditional-br>
            <conditional-br>{{ model.billingAddress.city }}</conditional-br>
            <conditional-br>{{ model.billingAddress.postcode }}</conditional-br>
          </div>
        </div>
      </div>
    </card>

    <licence-number-view-retrofit-account-fee-categories v-if="availableRoles" />

    <card label="Add User">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div v-if="errorMessage" class="alert alert-danger" role="alert">
            {{ errorMessage }}
          </div>

          <div v-if="successMessage" class="alert alert-success" role="alert">
            {{ successMessage }}
          </div>

          <smart-input
            id="firstName"
            v-model="firstName"
            label="First Name"
            :is-required="!!v$.firstName.isRequired"
            :validator="v$.firstName"
          />
          <smart-input
            id="lastName"
            v-model="lastName"
            label="Last Name"
            :is-required="!!v$.lastName.isRequired"
            :validator="v$.lastName"
          />
          <smart-input
            id="accountId"
            v-model="accountId"
            label="Email"
            :is-required="!!v$.accountId.isRequired"
            :validator="v$.accountId"
          />
          <div class="form-group">
            <label for="roles" class="form-label">Roles *</label>
            <select
              id="roles"
              v-model="roles"
              class="form-select"
              :class="{ 'is-invalid': v$.roles.$errors?.[0]?.$message }"
              multiple
              :validator="v$.roles"
            >
              <option v-for="role in availableRoles" :key="role" :value="role">
                {{ role }}
              </option>
            </select>
            <div v-if="v$.roles.$errors?.[0]?.$message" class="invalid-feedback">
              {{ v$.roles.$errors?.[0]?.$message }}
            </div>
            <div class="form-text">TIP: To select multiple roles, hold CTRL whilst clicking.</div>
          </div>
          <smart-submit-large label="Add" :loading="loading" @click="save" />
        </div>

        <div v-if="existingUsers && existingUsers.length > 0" class="col-sm-12 col-md-6">
          <h6>Existing Users:</h6>
          <ul>
            <li v-for="user in existingUsers" :key="user.accountId" class="mb-2">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  {{ user.displayName }}<br />
                  <span class="small light"> {{ user.accountId }} </span><br />
                  <span v-for="role in user.roles" :key="role" class="badge bg-primary me-1">
                    {{ role }}
                  </span>
                </div>
                <div class="btn-group">
                  <button 
                    type="button"
                    class="btn btn-outline-secondary btn-sm"
                    @click="onViewUserAccountEvents(user)"
                  >
                    <font-awesome-icon :icon="['fas', 'eye']" class="icon" />
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-danger btn-sm"
                    @click="onRemoveUser(user)"
                  >
                    <font-awesome-icon :icon="['fas', 'trash']" class="icon" />
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import {
  Subtitle,
  Card,
  CopyKeyValuePresenter,
  KeyValuePresenter,
  CheckOrCross,
  SmartInput,
  SmartSubmitLarge,
  ConfirmDialog,
  ConditionalBr,
  confirmType,
} from '@trustmark-2005-ltd/trustmark-shared-components';
import useValidate from '@vuelidate/core';
import { helpers, required, maxLength } from '@vuelidate/validators';
import { formatDate } from '../../../services/scheme-common';
import LicenceNumberViewRetrofitAccountFeeCategories from './LicenceNumberViewRetrofitAccountFeeCategories.vue';
import LicenceNumberViewRetrofitAccountNotifications from './LicenceNumberViewRetrofitAccountNotifications.vue';

export default {
  name: 'LicenceNumberViewRetrofitAccount',
  components: {
    CheckOrCross,
    Subtitle,
    Card,
    ConditionalBr,
    KeyValuePresenter,
    CopyKeyValuePresenter,
    SmartInput,
    SmartSubmitLarge,
    LicenceNumberViewRetrofitAccountFeeCategories,
    LicenceNumberViewRetrofitAccountNotifications,
    ConfirmDialog,
  },
  data() {
    return {
      v$: useValidate(),
      successMessage: null,
      errorMessage: null,
      addThirdPartySubmissions: false,
      firstName: '',
      lastName: '',
      roles: [],
      accountId: '',
      loading: false,
    };
  },
  computed: {
    model() {
      return this.$store.state.licenceNumber.retrofitAccount;
    },
    licence() {
      return this.$store.state.licenceNumber.trustmarkBusiness;
    },
    trustmarkBusinessId() {
      return this.$store.state.licenceNumber.trustmarkBusiness.trustmarkBusinessId;
    },
    availableRoles() {
      return this.$store.state.licenceNumber.appData.retrofitAccountUserRoles;
    },
    existingUsers() {
      return this.$store.state.licenceNumber.retrofitAccount.usersWithRoles;
    },
  },
  mounted() {
    this.$store.dispatch(
      'licenceNumber/getRetrofitAccountByTMLN',
      this.$store.state.licenceNumber.trustmarkBusiness.tmln
    );
    this.$store.dispatch('licenceNumber/getAppData');
  },
  methods: {
    formatDate,
    onViewUserAccountEvents(user) {
      this.$router.push(`/tmlns/users/${user.accountId}/events`);
    },
    asString(statusCode) {
      return this.$store.state.auth.appData.licenceNumberStatuses.find(
        (x) => x.status === parseInt(statusCode, 10)
      ).description;
    },
    createRetrofitAccount() {
      this.$store.dispatch('licenceNumber/createRetrofitAccount', {
        tmln: this.$store.state.licenceNumber.trustmarkBusiness.tmln,
        accountHolderName: this.$store.state.licenceNumber.trustmarkBusiness.registeredCompanyName,
        addThirdPartySubmissions: this.addThirdPartySubmissions,
      });
    },
    toggleThirdPartySubmissions() {
      this.addThirdPartySubmissions = !this.addThirdPartySubmissions;
    },
    onRemoveUser(user) {
      this.$refs.confirmDialog
        .show({
          title: 'Remove User',
          message: 'Are you sure you want to remove this user from the account?',
          okButtonText: 'Remove',
          confirmType: confirmType.destructive,
        })
        .then((result) => {
          this.loading = true;
          if (result) {
            this.$store
              .dispatch('licenceNumber/removeUser', {
                accountId: user.accountId,
                retrofitAccountId: this.model.retrofitAccountId,
              })
              .then(() => {
                this.successMessage = 'User removed';
                this.loading = false;
              })
              .catch((err) => {
                window.scrollTo(0, 0);
                this.loading = false;
                this.errorMessage =
                  (err.response && err.response.data && err.response.data.message) ||
                  err.message ||
                  err.toString();
              });
          }
        });
    },
    onDisableLoginLock() {
      this.$refs.confirmDialog
        .show({
          title: 'Disable Login Lock',
          message: 'Are you sure you want to disable the login lock for this Retrofit Account allowing users to login?',
          okButtonText: 'Disable',
          confirmType: confirmType.standard,
        })
        .then((result) => {
          this.loading = true;
          if (result) {
            this.$store
              .dispatch('licenceNumber/disableLoginLock', {
                retrofitAccountId: this.model.retrofitAccountId,
              })
              .then(() => {
                this.successMessage = 'Login Lock has been disabled for this Retrofit Account';
                this.loading = false;
              })
              .catch((err) => {
                window.scrollTo(0, 0);
                this.loading = false;
                this.errorMessage =
                  (err.response && err.response.data && err.response.data.message) ||
                  err.message ||
                  err.toString();
              });
          }
        });
    },
    onEnableLoginLock() {
      this.$refs.confirmDialog
        .show({
          title: 'Enable Login Lock',
          message: 'Are you sure you want to enable the login lock for this Retrofit Account restricking users from logging in?',
          okButtonText: 'Enable',
          confirmType: confirmType.destructive,
        })
        .then((result) => {
          this.loading = true;
          if (result) {
            this.$store
              .dispatch('licenceNumber/enableLoginLock', {
                retrofitAccountId: this.model.retrofitAccountId,
              })
              .then(() => {
                this.successMessage = 'Login Lock has been enabled for this Retrofit Account';
                this.loading = false;
              })
              .catch((err) => {
                window.scrollTo(0, 0);
                this.loading = false;
                this.errorMessage =
                  (err.response && err.response.data && err.response.data.message) ||
                  err.message ||
                  err.toString();
              });
          }
        });
    },
    save() {
      this.errorMessage = null;
      this.successMessage = null;

      this.v$.$validate();
      if (this.v$.$error) {
        window.scrollTo(0, 0);
        this.errorMessage =
          'Unable to add user. Please resolve any errors on this form and try again.';
        return;
      }

      this.$store
        .dispatch('licenceNumber/addUser', {
          retrofitAccountId: this.model.retrofitAccountId,
          tmln: this.$store.state.licenceNumber.trustmarkBusiness.tmln,
          firstName: this.firstName,
          lastName: this.lastName,
          accountId: this.accountId,
          roles: this.roles,
        })
        .then(() => {
          this.successMessage = 'User added';
          this.loading = false;
          this.lastName = '';
          this.firstName = '';
          this.accountId = '';
          this.roles = [];
          this.v$.$reset();
        })
        .catch((err) => {
          window.scrollTo(0, 0);
          this.loading = false;
          this.errorMessage =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
        });
    },
  },
  validations() {
    return {
      firstName: {
        isRequired: helpers.withMessage('First Name is required', required),
        maxLength: maxLength(100),
      },
      lastName: {
        isRequired: helpers.withMessage('Last Name is required', required),
        maxLength: maxLength(100),
      },
      accountId: {
        isRequired: helpers.withMessage('Email is required', required),
        maxLength: maxLength(200),
      },
      roles: {
        isRequired: helpers.withMessage('At least one role is required', required),
      },
    };
  },
};
</script>

<style scoped lang="scss">
.ml-2 {
  margin-left: 10px;
}

ul {
  padding-left: 1rem;
}

.light {
  color: $gray-600;
}

.icon {
  margin-top: -4px;
}
</style>
