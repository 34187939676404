import { HTTP, authHeader } from './http-service';

class BackOfficeUserService {
  list() {
    return HTTP.get(`/backofficeuser`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  getEvents(accountId) {
    return HTTP.post(`/backofficeuser/events`, { accountId }, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  get(payload) {
    return HTTP.post(`/backofficeuser`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  update(payload) {
    return HTTP.put(`/backofficeuser`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  updateScheme(payload) {
    return HTTP.put(`/backofficeuser/scheme`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  invite(payload) {
    return HTTP.post(`/backofficeuser/invite`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  disable(payload) {
    return HTTP.put(`/backofficeuser/disable`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  enable(payload) {
    return HTTP.put(`/backofficeuser/enable`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }
}

export default new BackOfficeUserService();
